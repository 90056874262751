import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import "./home.css";
import logo from "./images/logo.svg";
import banenr_desktop from "./images/banenr_desktop.jpg";
import banenr_desktop1 from "./images/1024-1266.jpg";
import banenr_desktop2 from "./images/1366.jpg";
import mobile_portraite from "./images/mobile-portraite.jpg";
import thank_you_bg_mobile from "./images/thank-you-bg-mobile.jpg";
import thank_you_bg_mobile_landscape from "./images/thank-you-bg-mobile-landscape.jpg";
import thank_you_bg from "./images/thank-you-bg.jpg";
import { apiConfig } from '../config/apiConfig';

const Home = () => {

    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    // const [searchParams, setSearchParams] = useSearchParams();
    // const leadId = searchParams.get("lead");
    // const name = searchParams.get("name");
    const [isThankYou, setThankYouOpen] = useState(false);
    const [leadDetail, setLead] = useState(null);
    const params = useParams();

    useEffect(() => {
        if (leadDetail) {
            setLinkVisited();
        }
    }, [leadDetail]);

    useEffect(() => {
        if (params.lead) {
            setLeadDetail();
        }
    }, [params.lead]);

    const setLeadDetail = () => {
        const lead = params.lead.replaceAll("Por21Ld", "/");
        var decrypted = window.CryptoJS.AES.decrypt(lead, apiConfig.REACT_APP_CRYPTOPASSWORD); //process.env.REACT_APP_CRYPTOPASSWORD);
        const decryptedDetail = decrypted.toString(window.CryptoJS.enc.Utf8);
        if (decryptedDetail) {
            const detail = JSON.parse(decryptedDetail);
            setLead(detail);
        }
    }

    const setLinkVisited = async () => {
        if (leadDetail.leadId) {
            const data = { leadId: leadDetail.leadId };
            const response = await axios.post(`${apiConfig.API_END_POINT}/leads/setLinkVisited?key=AIzaSyAb6M2Q17SlCJkt1_Bmr6VMpYKy9C8N8h8`, data);
            console.log(response)
        }
    }

    const onSubmit = async (data) => {
        if (leadDetail.leadId) {
            const reqBody = { leadId: leadDetail.leadId, comment: data.comment };
            const response = await axios.post(`${apiConfig.API_END_POINT}/leads/saveComment?key=AIzaSyAb6M2Q17SlCJkt1_Bmr6VMpYKy9C8N8h8`, reqBody);
            console.log(response)
            setThankYouOpen(true);
            reset();
        }
    };

    const closeModal = (e) => {
        e.preventDefault();
        setThankYouOpen(false);
    }


    return (
        <>
            <section className="" dir='rtl'>
                <a href="#" className="logoLanding">
                    <img src={logo} alt="logo" width="120" height="40" />
                </a>
                <picture className="profile-pic">
                    <source media="(max-width: 480px) and (orientation : portrait)" src={mobile_portraite} type="image/webp" />
                    <source media="(min-width:768px) and (max-width: 980px) and (orientation : portrait)" src={banenr_desktop1} type="image/webp" />
                    <source media="(min-width: 1000px) and (max-width: 1080px) and (orientation : portrait)" src={banenr_desktop1} type="image/webp" />
                    <source media="(min-width: 1300px) and (max-width: 1366px) and (orientation : landscape)" src={banenr_desktop2} type="image/webp" />
                    <source media="(min-width: 1200px) and (max-width: 2500px) and (orientation : portrait)" src={banenr_desktop} type="image/webp" />
                    <img data-img-variant="788x655" className="img-fluid" src={banenr_desktop} alt="pic" width="2560" height="884" />
                </picture>

                <div className="container1">
                    <div className="row">
                        <div className="col-md-61">
                            <div className="form-bx cover_content_bx">
                                <h3 className="headingH3"> היי {leadDetail?.name}!  </h3>
                                <p className="paragraph_p3">אנא רשום לנו מה סוג החנות\אתר  שאתה מעוניין להקים</p>

                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="flex-column" id="contact_form">
                                        <div className="form-groups">
                                            <textarea name='comment' className="form-controls" placeholder="העדוה:" {...register("comment", { required: true })}></textarea>
                                        </div>
                                        {errors.comment && <span className='error'>זהו שדה חובה</span>}

                                        {/* <div className="form-groups captchaForm">
                                            <div id="captcha"></div>
                                            <div className="g-recaptcha" data-sitekey="6LcLOyAaAAAAAHrZY3MOiwFU-6KXA8U5jom306R-"></div>
                                        </div> */}

                                        <div className="form-groups">
                                            <button type="submit" className="btn-primary bg-primary">שלח</button>
                                        </div>
                                    </div>
                                </form>

                                <div className="bannerCaptionss">
                                    <h3>אתרים לדוגמא   </h3>
                                    <ul>
                                        <li><svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.5367 9.18916C5.12348 12.2469 5.99951 16.6881 8.3907 20.521C8.71026 21.0378 9.40998 20.9893 9.74056 20.521C14.4733 13.8188 19.8893 7.34808 27.2833 3.35367C28.5946 2.64846 27.421 0.683562 26.1097 1.39416C18.264 5.63619 12.6992 12.1984 8.3907 19.7566C8.84249 19.7566 9.28877 19.7566 9.74056 19.7566C6.94717 16.1121 5.60833 11.7408 1.69097 8.99536C1.57527 8.90923 1.42651 9.09765 1.5367 9.18916Z" fill="#2B67FF" />
                                            <path d="M1.5367 9.18916C5.12348 12.2469 5.99951 16.6881 8.3907 20.521C8.71026 21.0378 9.40998 20.9893 9.74056 20.521C14.4733 13.8188 19.8893 7.34808 27.2833 3.35367C28.5946 2.64846 27.421 0.683562 26.1097 1.39416C18.264 5.63619 12.6992 12.1984 8.3907 19.7566C8.84249 19.7566 9.28877 19.7566 9.74056 19.7566C6.94717 16.1121 5.60833 11.7408 1.69097 8.99536C1.57527 8.90923 1.42651 9.09765 1.5367 9.18916Z" stroke="#2B67FF" strokeWidth="2" />
                                            <path d="M1.5367 9.18916C5.12348 12.2469 5.99951 16.6881 8.3907 20.521C8.71026 21.0378 9.40998 20.9893 9.74056 20.521C14.4733 13.8188 19.8893 7.34808 27.2833 3.35367C28.5946 2.64846 27.421 0.683562 26.1097 1.39416C18.264 5.63619 12.6992 12.1984 8.3907 19.7566C8.84249 19.7566 9.28877 19.7566 9.74056 19.7566C6.94717 16.1121 5.60833 11.7408 1.69097 8.99536C1.57527 8.90923 1.42651 9.09765 1.5367 9.18916Z" stroke="#2B67FF" strokeWidth="2" />
                                            <path d="M1.5367 9.18916C5.12348 12.2469 5.99951 16.6881 8.3907 20.521C8.71026 21.0378 9.40998 20.9893 9.74056 20.521C14.4733 13.8188 19.8893 7.34808 27.2833 3.35367C28.5946 2.64846 27.421 0.683562 26.1097 1.39416C18.264 5.63619 12.6992 12.1984 8.3907 19.7566C8.84249 19.7566 9.28877 19.7566 9.74056 19.7566C6.94717 16.1121 5.60833 11.7408 1.69097 8.99536C1.57527 8.90923 1.42651 9.09765 1.5367 9.18916Z" stroke="#2B67FF" strokeWidth="2" />
                                        </svg><a href="#" target="_blank" className="paragraph_p3">test-gcp-cdn.xyz/vegetable-shop/</a></li>

                                        <li><svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.5367 9.18916C5.12348 12.2469 5.99951 16.6881 8.3907 20.521C8.71026 21.0378 9.40998 20.9893 9.74056 20.521C14.4733 13.8188 19.8893 7.34808 27.2833 3.35367C28.5946 2.64846 27.421 0.683562 26.1097 1.39416C18.264 5.63619 12.6992 12.1984 8.3907 19.7566C8.84249 19.7566 9.28877 19.7566 9.74056 19.7566C6.94717 16.1121 5.60833 11.7408 1.69097 8.99536C1.57527 8.90923 1.42651 9.09765 1.5367 9.18916Z" fill="#2B67FF" />
                                            <path d="M1.5367 9.18916C5.12348 12.2469 5.99951 16.6881 8.3907 20.521C8.71026 21.0378 9.40998 20.9893 9.74056 20.521C14.4733 13.8188 19.8893 7.34808 27.2833 3.35367C28.5946 2.64846 27.421 0.683562 26.1097 1.39416C18.264 5.63619 12.6992 12.1984 8.3907 19.7566C8.84249 19.7566 9.28877 19.7566 9.74056 19.7566C6.94717 16.1121 5.60833 11.7408 1.69097 8.99536C1.57527 8.90923 1.42651 9.09765 1.5367 9.18916Z" stroke="#2B67FF" strokeWidth="2" />
                                            <path d="M1.5367 9.18916C5.12348 12.2469 5.99951 16.6881 8.3907 20.521C8.71026 21.0378 9.40998 20.9893 9.74056 20.521C14.4733 13.8188 19.8893 7.34808 27.2833 3.35367C28.5946 2.64846 27.421 0.683562 26.1097 1.39416C18.264 5.63619 12.6992 12.1984 8.3907 19.7566C8.84249 19.7566 9.28877 19.7566 9.74056 19.7566C6.94717 16.1121 5.60833 11.7408 1.69097 8.99536C1.57527 8.90923 1.42651 9.09765 1.5367 9.18916Z" stroke="#2B67FF" strokeWidth="2" />
                                            <path d="M1.5367 9.18916C5.12348 12.2469 5.99951 16.6881 8.3907 20.521C8.71026 21.0378 9.40998 20.9893 9.74056 20.521C14.4733 13.8188 19.8893 7.34808 27.2833 3.35367C28.5946 2.64846 27.421 0.683562 26.1097 1.39416C18.264 5.63619 12.6992 12.1984 8.3907 19.7566C8.84249 19.7566 9.28877 19.7566 9.74056 19.7566C6.94717 16.1121 5.60833 11.7408 1.69097 8.99536C1.57527 8.90923 1.42651 9.09765 1.5367 9.18916Z" stroke="#2B67FF" strokeWidth="2" />
                                        </svg><a href="#" target="_blank" className="paragraph_p3">test-gcp-cdn.xyz/vegetable-shop/</a></li>

                                        <li><svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.5367 9.18916C5.12348 12.2469 5.99951 16.6881 8.3907 20.521C8.71026 21.0378 9.40998 20.9893 9.74056 20.521C14.4733 13.8188 19.8893 7.34808 27.2833 3.35367C28.5946 2.64846 27.421 0.683562 26.1097 1.39416C18.264 5.63619 12.6992 12.1984 8.3907 19.7566C8.84249 19.7566 9.28877 19.7566 9.74056 19.7566C6.94717 16.1121 5.60833 11.7408 1.69097 8.99536C1.57527 8.90923 1.42651 9.09765 1.5367 9.18916Z" fill="#2B67FF" />
                                            <path d="M1.5367 9.18916C5.12348 12.2469 5.99951 16.6881 8.3907 20.521C8.71026 21.0378 9.40998 20.9893 9.74056 20.521C14.4733 13.8188 19.8893 7.34808 27.2833 3.35367C28.5946 2.64846 27.421 0.683562 26.1097 1.39416C18.264 5.63619 12.6992 12.1984 8.3907 19.7566C8.84249 19.7566 9.28877 19.7566 9.74056 19.7566C6.94717 16.1121 5.60833 11.7408 1.69097 8.99536C1.57527 8.90923 1.42651 9.09765 1.5367 9.18916Z" stroke="#2B67FF" strokeWidth="2" />
                                            <path d="M1.5367 9.18916C5.12348 12.2469 5.99951 16.6881 8.3907 20.521C8.71026 21.0378 9.40998 20.9893 9.74056 20.521C14.4733 13.8188 19.8893 7.34808 27.2833 3.35367C28.5946 2.64846 27.421 0.683562 26.1097 1.39416C18.264 5.63619 12.6992 12.1984 8.3907 19.7566C8.84249 19.7566 9.28877 19.7566 9.74056 19.7566C6.94717 16.1121 5.60833 11.7408 1.69097 8.99536C1.57527 8.90923 1.42651 9.09765 1.5367 9.18916Z" stroke="#2B67FF" strokeWidth="2" />
                                            <path d="M1.5367 9.18916C5.12348 12.2469 5.99951 16.6881 8.3907 20.521C8.71026 21.0378 9.40998 20.9893 9.74056 20.521C14.4733 13.8188 19.8893 7.34808 27.2833 3.35367C28.5946 2.64846 27.421 0.683562 26.1097 1.39416C18.264 5.63619 12.6992 12.1984 8.3907 19.7566C8.84249 19.7566 9.28877 19.7566 9.74056 19.7566C6.94717 16.1121 5.60833 11.7408 1.69097 8.99536C1.57527 8.90923 1.42651 9.09765 1.5367 9.18916Z" stroke="#2B67FF" strokeWidth="2" />
                                        </svg><a href="#" target="_blank" className="paragraph_p3">test-gcp-cdn.xyz/vegetable-shop/</a></li>

                                        <li><svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.5367 9.18916C5.12348 12.2469 5.99951 16.6881 8.3907 20.521C8.71026 21.0378 9.40998 20.9893 9.74056 20.521C14.4733 13.8188 19.8893 7.34808 27.2833 3.35367C28.5946 2.64846 27.421 0.683562 26.1097 1.39416C18.264 5.63619 12.6992 12.1984 8.3907 19.7566C8.84249 19.7566 9.28877 19.7566 9.74056 19.7566C6.94717 16.1121 5.60833 11.7408 1.69097 8.99536C1.57527 8.90923 1.42651 9.09765 1.5367 9.18916Z" fill="#2B67FF" />
                                            <path d="M1.5367 9.18916C5.12348 12.2469 5.99951 16.6881 8.3907 20.521C8.71026 21.0378 9.40998 20.9893 9.74056 20.521C14.4733 13.8188 19.8893 7.34808 27.2833 3.35367C28.5946 2.64846 27.421 0.683562 26.1097 1.39416C18.264 5.63619 12.6992 12.1984 8.3907 19.7566C8.84249 19.7566 9.28877 19.7566 9.74056 19.7566C6.94717 16.1121 5.60833 11.7408 1.69097 8.99536C1.57527 8.90923 1.42651 9.09765 1.5367 9.18916Z" stroke="#2B67FF" strokeWidth="2" />
                                            <path d="M1.5367 9.18916C5.12348 12.2469 5.99951 16.6881 8.3907 20.521C8.71026 21.0378 9.40998 20.9893 9.74056 20.521C14.4733 13.8188 19.8893 7.34808 27.2833 3.35367C28.5946 2.64846 27.421 0.683562 26.1097 1.39416C18.264 5.63619 12.6992 12.1984 8.3907 19.7566C8.84249 19.7566 9.28877 19.7566 9.74056 19.7566C6.94717 16.1121 5.60833 11.7408 1.69097 8.99536C1.57527 8.90923 1.42651 9.09765 1.5367 9.18916Z" stroke="#2B67FF" strokeWidth="2" />
                                            <path d="M1.5367 9.18916C5.12348 12.2469 5.99951 16.6881 8.3907 20.521C8.71026 21.0378 9.40998 20.9893 9.74056 20.521C14.4733 13.8188 19.8893 7.34808 27.2833 3.35367C28.5946 2.64846 27.421 0.683562 26.1097 1.39416C18.264 5.63619 12.6992 12.1984 8.3907 19.7566C8.84249 19.7566 9.28877 19.7566 9.74056 19.7566C6.94717 16.1121 5.60833 11.7408 1.69097 8.99536C1.57527 8.90923 1.42651 9.09765 1.5367 9.18916Z" stroke="#2B67FF" strokeWidth="2" />
                                        </svg><a href="#" target="_blank" className="paragraph_p3">test-gcp-cdn.xyz/vegetable-shop/</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {isThankYou && (
                <section className="thankYouPage" dir='rtl'>
                    <div className="wrap_thanks">
                        <div className="thanks_container">
                            <a href="#!" className="logoLanding" >
                                <img src={logo} alt="logo" width="120" height="40" />
                            </a>

                            <span className="closeThanks">
                                <a href='#!' onClick={closeModal}>
                                    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <line x1="2.06066" y1="1.93934" x2="21.7408" y2="21.6195" stroke="white" strokeWidth="3"></line>
                                        <line x1="1.87489" y1="21.619" x2="21.555" y2="1.93889" stroke="white" strokeWidth="3"></line>
                                    </svg>
                                </a>
                            </span>
                            <picture className="profile-pic">
                                <source media="(max-width: 580px) and (orientation : portrait)" src={thank_you_bg_mobile} type="image/webp" />
                                <source media="(max-width: 980px) and (orientation : landscape)" src={thank_you_bg_mobile_landscape} type="image/webp" />
                                <source media="(min-width: 1200px) and (max-width: 2500px) and (orientation : portrait)" src={thank_you_bg} type="image/webp" />
                                <img data-img-variant="788x655" className="img-fluid" src={thank_you_bg} alt="pic" width="2560" height="884" />
                            </picture>
                            <div className="content_bx_thanks">
                                <h1>תודה רבה </h1>
                                <p>נציגנו יחזרו אליך בשעות הפעילות</p>
                                <a className="btn-primary" href="#!" onClick={closeModal}>Back to Home</a>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </>
    );
};

export default Home;
