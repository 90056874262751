import logo from './logo.svg';
import './App.css';
import { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './home/home';

function App() {
  return (
    <BrowserRouter>
      <Suspense loading={<div>Loading  ....</div>}>
            <Routes>
             <Route path="/:lead" element={<Home />} />
              {/* {
                allRoutes.map((route, key) => {
                  return <Route key={key} {...route} element={< />} />
                })
              } */}
            </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
